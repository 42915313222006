@import '/src/_scss/main';

.box {
  width: 25%;
  min-height: 100px;
  margin: 1rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  text-align: center;
}
.box img {
  height: 120px;
  max-width: 400px;
}

.podcastapps {
  color: $color-1;
  .podcastappsHeadingWrapper {
    padding: 3rem 1rem;
  }
  .podcastappsHeading2 {
    font-size: $h1-font-size;
    font-weight: $h1-font-weight;
    font-family: $h2-font-family;
  }

  .podcastapps-icon img {
    width: 50px;
  }

  .podcastappsSubheading4 {
    color: $color-2;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .podcastappsPara1 {
    font-size: $h2-font-size;
    font-weight: $h2-font-weight;
    font-family: $h2-font-family;
    color: $color-4;
    margin-top: calc($item-margin-top-bottom-left-right * 6);
    text-transform: uppercase;
  }

  .podcastappsPara2 {
    color: $color-4;
    font-size: 3rem;
    font-weight: 700;
    font-family: $h2-font-family;
    letter-spacing: 2px;
    @include firstUpperCase();

    .colorGold {
      color: $color-2;
    }

    .colorBlue {
      color: $color-1;
    }
  }

  .podcastappsPara3 {
    font-size: 1.5rem;
    font-weight: $body-font-weight;
    font-family: $body-font-family;
    color: $color-4;
    @include firstUpperCase();

    .specific {
      color: $color-1;
    }

    .colorGold {
      color: $color-2;
      font-weight: $h3-font-weight;
    }
  }
}

@media (max-width: 576px), (max-width: 992px) {
  .box {
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
}

@media (max-width: 576px) {
  .box {
    width: 100%;
    max-height: 300px;
  }

  .podcastapps {
    .podcastappsHeadingWrapper {
      padding: 3rem 2rem;
    }
    .podcastappsHeading2 {
      font-size: $h1-font-size-mobile;
    }
  }
}
