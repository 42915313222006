@import '/src/_scss/main';

.container-fullWidth {
}

.container-fluid {
  padding-left: calc($item-padding-left-right-top-bottom);
  padding-right: calc($item-padding-left-right-top-bottom);
  .navbar-brand {
    padding-left: calc($item-padding-left-right-top-bottom);
  }
  .nav-link,
  option {
    text-align: center;
    margin-right: calc($item-margin-top-bottom-left-right - 0.2rem);
    color: $color-1;
    font-size: $navbar-font-size;
    font-family: $navbar-font-family;
    font-weight: $navbar-font-weight;
    &:hover {
      border-bottom: 1px solid $color-2;
    }
  }
  .navbar-nav {
    font-size: $link-font-size;
    font-weight: $navbar-font-weight;
    padding-right: calc($item-padding-left-right-top-bottom);
    padding-left: $icon-link-padding-left;
  }
}

@media (max-width: 576px), (max-width: 992px) {
  .headerIcons {
    display: none;
  }
}
