@import '/src/_scss/main.scss';

.datenschutzContainer {
  background-color: $color-6;
  padding-top: calc($item-padding-left-right-top-bottom * 4);
  .datenschutzContaineInner {
    padding-left: calc($item-padding-left-right-top-bottom * 6);
    padding-right: calc($item-padding-left-right-top-bottom * 6);
    .datenschutzHeading1 {
      font-family: $h2-font-family;
      font-size: calc($h2-font-size + 5pt);
      font-weight: $h2-font-weight;
    }
    .datenschutzHeading3GreyBold {
      font-weight: calc($body-font-weight + 110);
      color: $color-7;
    }
    .datenschutzContent {
      color: $color-7;
      font-family: $body-font-family;
      text-align: justify;
      a {
        text-decoration: none;
        color: $color-8;
      }
    }
    .datenschutzHeading2 {
      font-family: $h3-font-family;
      font-size: calc($h3-font-size - 0.8rem);
      font-weight: calc($h3-font-weight - 140);
    }
    .datenschutzDarkBg {
      background-color: $color-9;
      padding: calc($item-padding-left-right-top-bottom);
    }
  }
}

@media (max-width: 576px), (max-width: 992px) {
  .datenschutzContainer {
    .datenschutzContaineInner {
      padding-left: calc($item-padding-left-right-top-bottom);
      padding-right: calc($item-padding-left-right-top-bottom);
    }
  }
}
