@import '/src/_scss/main';

@mixin commonPara2List {
  font-size: $body-font-size;
  font-family: $body-font-family;
  font-weight: $body-font-weight;
  margin-left: calc($item-margin-top-bottom-left-right * 2);
}

.philosophy {
  margin-top: calc($item-margin-top-bottom-left-right * 1);
  .philosophyPara1 {
    font-size: $h1-font-size;
    font-weight: $h1-font-weight;
    font-family: $h1-font-family;
    color: $color-1;
    @include firstUpperCase();
    .philosophyPara1InnerText {
      color: $color-2;
    }
  }
  .philosophyPara2 {
    @include commonPara2List();
    @include firstUpperCase();
    margin-right: calc($item-margin-top-bottom-left-right * 4);
  }
  .philosophyList {
    @include commonPara2List();
  }
  .philosophyList li {
    color: $color-2;
    @include firstUpperCase();
  }
  .philosophyList li span {
    color: #000000;
  }
  .philosophyButton {
    border: 1px solid $color-2;
    color: $color-1;
    font-weight: $button-font-weight;
    border-radius: $button-border-radius;
    font-family: $button-font-family;
    width: 22rem;
    margin-top: calc($item-margin-top-bottom-left-right);
    margin-left: calc($item-margin-top-bottom-left-right * 3);
    margin-bottom: calc($item-margin-top-bottom-left-right);
    @include firstUpperCase();
  }
}

.philosophyImg {
  margin-left: -$item-margin-top-bottom-left-right * 9;
  margin-top: -$item-margin-top-bottom-left-right * 6;
  max-width: 150%;
  height: auto;
}

@media screen and (max-width: 576px), (max-width: 992px) {
  .philosophy {
    margin-left: -$item-margin-top-bottom-left-right;
    .philosophyPara1 {
      margin-left: calc($item-margin-top-bottom-left-right);
      margin-top: calc($item-margin-top-bottom-left-right);
      line-height: 100%;
    }
    .philosophyPara2 {
      margin-left: calc($item-margin-top-bottom-left-right);
      margin-right: $item-margin-top-bottom-left-right %2;
      text-align: justify;
    }
  }

  .philosophyImg {
    margin-left: -$item-margin-top-bottom-left-right * 3;
    max-width: 135%;
  }
}

@media screen and (max-width: 992px) {
  .philosophyImg {
    margin-top: calc($item-margin-top-bottom-left-right * 2);
  }
  .philosophy {
    .philosophyButton {
      margin-left: calc($item-margin-top-bottom-left-right * 1.5);
    }
  }
}

@media screen and (max-width: 576px) {
  .philosophy {
    .philosophyButton {
      margin-left: $item-margin-top-bottom-left-right;
    }
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .philosophyImg {
    margin-top: 0px !important;
    margin-left: -5rem;
    max-width: 150%;
  }
}
@media screen and (max-width: 768px) and (min-width: 576px) {
  .philosophyImg {
    margin-top: -10rem;
  }
}
