.full-width {
  padding-left: 0;
  padding-right: 0;
}

.image-container {
  display: flex;
  justify-content: center;
  height: 80vh;
  overflow: hidden;
}

.centered-image {
  width: 100%;
  height: auto;
  max-width: 100vw;
  object-fit: cover;
}
