@import '/src/_scss/main';

.digitalIdea {
  .digitalIdeaPara1 {
    font-size: $h2-font-size;
    font-weight: $h2-font-weight;
    font-family: $h2-font-family;
    color: $color-4;
    margin-top: calc($item-margin-top-bottom-left-right * 6);
    text-transform: uppercase;
  }
  .digitalIdeaPara2 {
    color: $color-4;
    font-size: $h3-font-size;
    font-weight: $h3-font-weight;
    font-family: $h3-font-family;
    @include firstUpperCase();
    .specific {
      color: $color-2;
    }
  }
  .digitalIdeaPara3 {
    font-size: $body-font-size;
    font-weight: $body-font-weight;
    font-family: $body-font-family;
    color: $color-4;
    margin-left: calc($item-margin-top-bottom-left-right * 6);
    margin-top: calc($item-margin-top-bottom-left-right);
    @include firstUpperCase();
  }
}

.digitalImg {
  margin-left: -$item-margin-top-bottom-left-right * 7;
  margin-top: -$item-margin-top-bottom-left-right;
  max-width: 140%;
  height: auto;
}

@media (max-width: 576px), (max-width: 992px) {
  .digitalIdea {
    .digitalIdeaPara1 {
      line-height: 25%;
      text-align: center;
      margin-top: calc($item-margin-top-bottom-left-right);
    }
    .digitalIdeaPara2 {
      margin-left: $item-margin-top-bottom-left-right %2;
      margin-top: calc($item-margin-top-bottom-left-right);
    }
    .digitalIdeaPara3 {
      margin-left: $item-margin-top-bottom-left-right %2;
      text-align: justify;
    }
    .digitalImg {
      margin-left: $item-margin-top-bottom-left-right %2;
      max-width: 100%;
      height: auto;
    }
  }
}

@media screen and (max-width: 992px) {
  .digitalImg {
    padding-top: calc($item-margin-top-bottom-left-right * 2);
  }
}
@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .digitalimagecol {
    position: relative;
    height: 35rem;
  }
  .digitalImg {
    position: absolute;
    margin-top: 3.5rem;
  }
  .digitalIdeaPara1 {
    margin-top: 5rem !important;
  }
}
.digitalIdeaButton {
  background-color: $color-5 !important;
  color: $color-3 !important;
}
