/*=============== GOOGLE FONTS ===============*/
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Raleway:wght@600;700;800&display=swap');

@import './_scss/main';

/*=============== VARIABLES SCSS ===============*/
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.bold {
  font-weight: bold;
}

.subheadingCategory {
  font-size: $subheading-font-size;
  font-weight: $subheading-font-weight;
  font-family: $subheading-font-family;
  text-transform: uppercase;
  letter-spacing: 2px;
}

#toTop {
  position: fixed;
  bottom: 60px;
  right: 30px;
  z-index: 99;
  font-size: 1.5rem;
  outline: none;
  cursor: pointer;
  padding: 2px 6px;
  border: none;
  background: none;
  color: #dc9f18;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  .col-start {
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  .col-center {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.end-start {
  justify-content: end;
  align-items: start;
}

.start-center {
  justify-content: start;
  align-items: center;
}

@media (max-width: 992px) {
  #toTop {
    bottom: 80px;
  }
}

@media (max-width: 576px) {
  #toTop {
    bottom: 40px;
  }

  .flex {
    flex-direction: column;
  }
}

.slick-prev,
.slick-next {
  display: none !important;
}

// Button styles

button.btn.defaultButton,
a.btn.defaultButton {
  border: 1px solid $color-2;
  background-color: $color-4;
  color: $color-1;
  font-weight: $button-font-weight;
  font-family: $button-font-family;
  border-radius: $button-border-radius;
  // margin-left: $item-margin-top-bottom-left-right;
  margin-top: $item-margin-top-bottom-left-right;
  width: 50%;
  @include firstUpperCase();
  .locateIcon {
    padding-left: calc($item-padding-left-right-top-bottom);
    color: $color-2;
  }
  &:hover {
    background-color: $color-2;
    color: $color-3;
    .locateIcon {
      padding-left: calc($item-padding-left-right-top-bottom);
      color: $color-3;
    }
  }
}

@media (max-width: 576px), (max-width: 992px) {
  a.btn.defaultButton,
  button.btn.defaultButton {
    margin: 0 auto;
    width: 80%;
    display: block;
  }
}
@media (max-width: 1200px) {
  button.btn.defaultButton {
    width: 100%;
  }
}
