// colors
$color-1: #011940;
$color-2: #dc9f18;
$color-3: #f1f0f2;
$color-4: #ffffff;
$color-5: #20548e;
$color-6: #f5f5f5;
$color-7: #999;
$color-8: #fbb03b;
$color-9: #eeeeee;

// Typography
$primary-font-family: 'Raleway';
$secondary-font-family: 'Open Sans';

// body
$body-font-size: 1rem;
$body-font-family: $secondary-font-family;
$body-font-weight: 400;

// Headings
$heading-font-family: $primary-font-family;

// Variables for Navbar starts
$link-font-size: 1rem;
$icon-link-padding-left: 3.5rem;
// Variables for Navbar ends

// Generic Variables starts
$weight: 600;
$item-padding-left-right-top-bottom: 1.1rem;
$item-margin-top-bottom-left-right: 2rem;
$text-alignment: center;

// navbar
$navbar-font-size: 1.25rem;
$navbar-font-family: 'Raleway';
$navbar-font-weight: 700;

// h1
$h1-font-size: 3rem;
$h1-font-family: 'Raleway';
$h1-font-weight: 800;

$h1-font-size-mobile: 2.5rem;

// h2
$h2-font-size: 2rem;
$h2-font-family: 'Raleway';
$h2-font-weight: 600;

$h2-font-size-mobile: 1.5rem;

// h3
$h3-font-size: 1.7rem;
$h3-font-family: 'Raleway';
$h3-font-weight: 800;

$h3-font-size-mobile: 1.2rem;

// h4
$h4-font-size: 1.7rem;
$h4-font-family: 'Raleway';
$h4-font-weight: 600;

$h4-font-size-mobile: 1.2rem;

// subheading-font
$subheading-font-size: $h3-font-size;
$subheading-font-family: $h2-font-family;
$subheading-font-weight: $h2-font-weight;

// footer
$footer-font-size: 0.8rem;
$footer-font-family: 'Raleway';

// button
$button-font-size: 1.75rem;
$button-font-family: 'Raleway';
$button-font-weight: 700;
$button-border-radius: 5rem;

// Numbers
$number-font-size: 3.75rem;
$number-font-family: 'Open Sans';
$number-font-weight: 700;

// Generic Variables ends
