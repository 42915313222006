@import '/src/_scss/main';

.recruitmentLevel {
  color: $color-4;

  .center {
    text-align: center;
  }

  .recruitmentLevel-icon {
    color: $color-4;
    border-radius: 50%;
    outline: 2px solid $color-2;
    outline-offset: 30px;
  }

  .recruitmentLevel-icon img {
    width: 100px;
  }

  .recruitmentLevelSubheading4 {
    color: $color-2;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    .col-start {
      flex-direction: column;
      justify-content: start;
      align-items: start;
    }
    .col-center {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .recruitmentLevelPara1 {
    font-size: $h2-font-size;
    font-weight: $h2-font-weight;
    font-family: $h2-font-family;
    color: $color-4;
    margin-top: calc($item-margin-top-bottom-left-right * 6);
    text-transform: uppercase;
  }
  .recruitmentLevelPara2 {
    color: $color-4;
    font-size: 3rem;
    font-weight: 700;
    font-family: $h2-font-family;
    letter-spacing: 2px;
    @include firstUpperCase();
    .colorGold {
      color: $color-2;
    }
    .colorBlue {
      color: $color-1;
    }
  }
  .recruitmentLevelPara3 {
    font-size: 1.5rem;
    font-weight: $body-font-weight;
    font-family: $body-font-family;
    color: $color-4;
    @include firstUpperCase();
    .specific {
      color: $color-1;
    }
  }

  .list {
    list-style: none;
    padding: 5% 25% 5% 25%;
  }

  .list-item {
    display: flex;
    align-items: center;
    margin-bottom: 5rem;
    gap: 5rem;
  }

  .list-heading {
    color: $color-4;
    margin: 0;
  }

  .list-paragraph {
    color: $color-4;
    margin: 5px 0;
  }
}

.digitalImg {
  margin-left: -$item-margin-top-bottom-left-right * 7;
  margin-top: -$item-margin-top-bottom-left-right;
  max-width: 140%;
  height: auto;
}

.contact-form {
  background-color: #ffc107;
  border-radius: 2rem;
  padding: 2rem;
  margin-top: -5rem;
  margin-bottom: 5rem;
}

.contact-form p {
  font-size: $h2-font-size;
  font-weight: 700;
  font-family: $h2-font-family;
}

.contact-form input {
  margin-left: 1rem;
}

.contact-form > label {
  padding: 1rem;
  color: $color-1;
}

.contactFormButton {
  border: 1px solid $color-1;
  background-color: $color-4;
  color: $color-1;
  font-size: 1.7rem;
  font-weight: 400;
  font-family: $primary-font-family;
  border-radius: 5rem;
  padding: 0.2rem 3rem;
  margin-top: 1rem;
}

.contactFormButton:hover {
  color: $color-4;
  border: 1px solid $color-4;
  background-color: $color-1;
}

@media (max-width: 576px), (max-width: 992px) {
  .recruitmentLevel {
    padding-left: 1rem;
    padding-right: 1rem;
    .recruitmentLevelPara1 {
      line-height: 25%;
      text-align: center;
      margin-top: calc($item-margin-top-bottom-left-right);
    }
    .recruitmentLevelPara2 {
      margin-left: $item-margin-top-bottom-left-right %2;
      margin-top: calc($item-margin-top-bottom-left-right);
      font-size: 2rem;
    }
    .recruitmentLevelPara3 {
      margin-left: $item-margin-top-bottom-left-right %2;
      text-align: justify;
    }
    .digitalImg {
      margin-left: $item-margin-top-bottom-left-right %2;
      max-width: 100%;
      height: auto;
    }
    .list-item {
      padding: 5% 10%;
    }
    .contact-form p {
      font-size: 1.5rem;
    }
  }
}

@media screen and (max-width: 992px) {
  .digitalImg {
    padding-top: calc($item-margin-top-bottom-left-right * 2);
  }

  .recruitmentLevel {
    .list {
      padding: 10% 5%;
    }
    .list-item {
      padding: 5% 0;
      flex-direction: column;
    }
  }

  .contact-form {
    width: 90%;
  }
}
