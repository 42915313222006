@import '/src/_scss/main.scss';

.impressumContainer {
  background-color: $color-6;
  padding-top: calc($item-padding-left-right-top-bottom * 4);
  .impressumContaineInner {
    padding-left: calc($item-padding-left-right-top-bottom * 6);
    padding-right: calc($item-padding-left-right-top-bottom * 6);
    .impressumHeading1 {
      font-family: $h2-font-family;
      font-size: calc($h2-font-size + 5pt);
      font-weight: $h2-font-weight;
    }
    .impressumContent {
      color: $color-7;
      font-family: $body-font-family;
      text-align: justify;
      .impressumContentInner {
        font-weight: calc($body-font-weight + 110);
      }
      a {
        text-decoration: none;
        color: $color-8;
      }
    }
    .impressumHeading2 {
      font-family: $h3-font-family;
      font-size: calc($h3-font-size - 0.8rem);
      font-weight: calc($h3-font-weight - 140);
    }
  }
}

@media (max-width: 576px), (max-width: 992px) {
  .impressumContainer {
    .impressumContaineInner {
      padding-left: calc($item-padding-left-right-top-bottom);
      padding-right: calc($item-padding-left-right-top-bottom);
    }
  }
}
