@import '/src/_scss/main';

.buildDigital {
  margin-left: calc($item-margin-top-bottom-left-right * 5);
  .buildDigitalPara1 {
    font-size: $h1-font-size;
    font-weight: $h1-font-weight;
    color: $color-1;
    font-family: $h1-font-family;
    .buildDigitalPara1InnerText {
      color: $color-2;
    }
  }
  .buildDigitalPara2 {
    font-size: $body-font-size;
    font-family: $body-font-family;
    font-weight: $body-font-weight;
    margin-left: calc($item-margin-top-bottom-left-right * 2);
    @include firstUpperCase();
  }
}

.buildDigitalImg {
  margin-left: calc($item-margin-top-bottom-left-right * 5);
  margin-top: -$item-margin-top-bottom-left-right * 2;
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 576px), (max-width: 992px) {
  .buildDigital {
    margin-left: calc($item-margin-top-bottom-left-right);
    .buildDigitalPara1 {
      margin-left: -$item-margin-top-bottom-left-right;
      line-height: 100%;
    }
    .buildDigitalPara2 {
      margin-left: -$item-margin-top-bottom-left-right;
      text-align: justify;
    }
    .buildDigitalImg {
      margin-top: calc($item-margin-top-bottom-left-right * 2);
    }
  }

  .buildDigitalImg {
    margin-left: $item-margin-top-bottom-left-right %2;
    max-width: 100%;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .buildDigital {
    margin-left: calc($item-margin-top-bottom-left-right * 2);
    .buildDigitalPara2 {
      margin-left: calc($item-margin-top-bottom-left-right * 1);
    }
  }
}
@media screen and (min-width: 768px) {
  .buildDigital {
    margin-top: calc($item-margin-top-bottom-left-right * 4);
  }
}
