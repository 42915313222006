@import '/src/_scss/main.scss';

.container3GenericInfo {
  background-color: $color-2;
  padding: calc($item-padding-left-right-top-bottom * 6);
  text-align: center;
}

.container4GetInTouch {
  padding-top: calc($item-padding-left-right-top-bottom * 4);
  padding-bottom: calc($item-padding-left-right-top-bottom * 4);
  background-color: $color-1;
}

@media screen and (max-width: 576px) {
  .container3GenericInfo {
    padding: $item-padding-left-right-top-bottom %2;
  }
}
