@import '/src/_scss/main';
.spacer-ml-3 {
  margin-left: 3rem;
}

.branding {
  margin-top: calc($item-margin-top-bottom-left-right * 1);

  .brandingHead1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: $h1-font-size;
    font-weight: 700;
    font-family: $primary-font-family;
    color: $color-1;
    margin-top: 5rem;
    margin-bottom: 5rem;
    @include firstUpperCase();
  }
  .brandingPara1InnerText {
    color: $color-2;
    font-weight: 400;
    font-family: $secondary-font-family;
  }

  .brandingHead2 {
    text-align: $text-alignment;
    color: $color-1;
    font-size: $h1-font-size;
    font-weight: $h1-font-weight;
    font-family: $h1-font-family;
    @include firstUpperCase();
    .specific {
      color: $color-2;
    }
  }

  .brandingHead3 {
    color: $color-1;
    font-size: $h3-font-size;
    font-weight: $h3-font-weight;
    font-family: $h3-font-family;
    letter-spacing: 2px;
    text-transform: uppercase;
    @include firstUpperCase();
    .specific {
      color: $color-2;
    }
  }

  .brandingPara1 {
    font-size: $h3-font-size;
    font-weight: $h3-font-weight;
    font-family: $h3-font-family;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: $color-1;
    margin-bottom: -2rem;
    @include firstUpperCase();
    .brandingPara1InnerText {
      color: $color-2;
      font-weight: 600;
      margin-left: -2rem;
    }
  }
  .brandingPara1Specific {
    margin-left: -2rem;
  }
  .brandingPara2 {
    @include firstUpperCase();
    margin-right: calc($item-margin-top-bottom-left-right * 4);
  }

  ul {
    list-style: none;
  }
  ul li::before {
    content: '\2022';
    color: $color-2;
    font-weight: bolder;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  .brandingList li {
    font-size: 1.5rem;
    color: $color-1;
    @include firstUpperCase();
  }
  .brandingList span {
    color: $color-2;
    margin-left: -2rem;
  }
  .brandingList h3 {
    margin-top: 2rem;
    font-weight: 600;
  }
  .brandingButton {
    border: 1px solid $color-2;
    color: $color-1;
    font-weight: $button-font-weight;
    border-radius: $button-border-radius;
    font-family: $button-font-family;
    width: 22rem;
    margin-top: calc($item-margin-top-bottom-left-right);
    margin-left: 5rem;
    margin-bottom: calc($item-margin-top-bottom-left-right);
    @include firstUpperCase();
  }
}

.brandingImg {
  max-width: 90%;
  border-radius: 100%;
  height: 750px;
  width: 750px;
  object-fit: cover;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  margin-top: 0;
}
.leftOffset {
  margin-left: -35%;
}
.rightOffset {
  margin-left: 10rem;
}

@media screen and (max-width: 576px), (max-width: 992px) {
  .spacer-ml-3 {
    margin-left: 1rem;
  }
  .branding {
    .brandingPara1 {
      margin-top: calc($item-margin-top-bottom-left-right);
      line-height: 100%;
    }
    .brandingPara2 {
      margin-left: calc($item-margin-top-bottom-left-right);
      margin-right: $item-margin-top-bottom-left-right %2;
      text-align: justify;
    }
    .brandingHead1 {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .brandingHead2 {
      font-size: 2rem;
    }
  }
}

@media screen and (max-width: 992px) {
  .brandingImg {
    height: 350px;
    width: 350px;
    margin-top: 1rem;
  }
  .leftOffset {
    margin-left: 0;
  }
  .rightOffset {
    margin-left: 0;
  }

  .branding {
    .brandingButton {
      margin-left: calc($item-margin-top-bottom-left-right * 1.5);
    }
  }
}

@media screen and (max-width: 576px) {
  .branding {
    .brandingButton {
      margin-left: $item-margin-top-bottom-left-right;
    }
  }
  .brandingImg {
    height: 300px;
    width: 300px;
  }
}
