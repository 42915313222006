@import '/src/_scss/main';

.podcastGenInfo {
  margin: 5rem 0;
}

.podcastGenInfoPara1 {
  color: $color-1;
  font-size: $h1-font-size;
  font-weight: $h1-font-weight;
  font-family: $h1-font-family;
  text-align: center;
  margin-bottom: 1rem;
  .specific {
    color: $color-2;
  }
}

.podcastGenInfoFA {
  color: $color-2;
  margin: 2rem;
}

.podcastGenInfoIcon {
  max-width: 5rem;
  margin: 2rem;
}

.podcastGenInfoPara2 {
  color: $color-1;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  font-family: $button-font-family;
  text-align: center;
  @include firstUpperCase();
}

@media screen and (max-width: 576px) {
  .genericInfoPara1 {
    text-align: justify;
  }

  .genericInfoPara2 {
    text-align: justify;
    font-size: $h2-font-size;
  }

  .podcastGenInfoPara1 {
    font-size: $h1-font-size-mobile;
  }
}
