@import '/src/_scss/main.scss';

.goDown {
  position: absolute;
  color: $color-2;
  top: 78%;
  left: 50%;
  font-size: 1.5rem;
}
.goDown:hover {
  color: $color-2;
}

.container2 {
  background-color: $color-3;
  margin-top: calc($item-margin-top-bottom-left-right);
}

.container3 {
  background: linear-gradient(to right, #011940, #20548e);
  height: auto;
}

.container5 {
  padding-left: 0;
}

.container6 {
  margin-top: calc($item-margin-top-bottom-left-right * 2);
  padding-top: calc($item-padding-left-right-top-bottom * 4);
  background: linear-gradient(to right, #011940, #20548e);
}

@media (max-width: 576px), (max-width: 992px) {
  .container3 {
    padding-bottom: calc($item-padding-left-right-top-bottom * 2);
  }
}

@media (max-width: 992px) {
  .goDown {
    top: 52%;
    left: 50%;
  }
}

@media (max-width: 576px) {
  .goDown {
    display: none;
  }
}
