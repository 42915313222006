@import '/src/_scss/main';

.genericInfoPara1 {
  color: $color-1;
  font-size: $h2-font-size;
  font-weight: $h2-font-weight;
  font-family: $h2-font-family;
}

.genericInfoPara2 {
  color: $color-4;
  font-size: $h3-font-size;
  font-weight: $h3-font-weight;
  font-family: $h3-font-family;
  @include firstUpperCase();
}

@media screen and (max-width: 576px) {
  .genericInfoPara1 {
    text-align: justify;
  }

  .genericInfoPara2 {
    text-align: justify;
    font-size: $h2-font-size;
  }
}
