@import '/src/_scss/main';
.image_wrapper {
  margin-top: calc($item-padding-left-right-top-bottom);
  margin-bottom: calc($item-padding-left-right-top-bottom);
  width: 8rem;
  margin-left: auto;
  margin-right: auto;
  img {
    height: 2rem;
    width: auto;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    object-fit: scale-down;
  }
}
