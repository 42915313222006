@import '/src/_scss/main';

.buildDigital {
  margin-left: calc($item-margin-top-bottom-left-right * 5);
  margin-top: calc($item-margin-top-bottom-left-right * 4);
  .buildDigitalPara1 {
    font-size: $h1-font-size;
    font-weight: $h1-font-weight;
    color: $color-1;
    font-family: $h1-font-family;
    .buildDigitalPara1InnerText {
      color: $color-2;
    }
  }
  .buildDigitalPara2 {
    font-size: $body-font-size;
    font-family: $body-font-family;
    font-weight: $body-font-weight;
    margin-left: 0;
    @include firstUpperCase();
  }
}

.buildDigitalImg {
  max-width: 90%;
  border-radius: 100%;
  height: 750px;
  width: 750px;
  object-fit: cover;
  box-shadow: 25px 10px 5px rgba(0, 0, 0, 0.1);
  margin-top: 0;
}

@media screen and (max-width: 576px), (max-width: 1200px) {
  .buildDigital {
    margin-left: calc($item-margin-top-bottom-left-right);
    .buildDigitalPara1 {
      margin-left: -$item-margin-top-bottom-left-right;
      line-height: 100%;
    }
    .buildDigitalPara2 {
      margin-left: -$item-margin-top-bottom-left-right;
      text-align: justify;
    }
    .buildDigitalImg {
      margin-top: calc($item-margin-top-bottom-left-right * 2);
    }
  }

  .buildDigitalImg {
    margin-left: $item-margin-top-bottom-left-right %2;
    max-width: 100%;
    margin-top: 0;
  }
}

@media screen and (max-width: 1200px) {
  .buildDigitalImg {
    width: 300px;
    height: 300px;
  }

  .buildDigital {
    margin-top: 2rem;
  }
}
