@import '/src/_scss/main';
.yellow-bg {
  background-color: $color-2;
}

.bold {
  font-weight: bold;
}

.color-4 {
  color: $color-4;
}

.getInTouchRecruiting {
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 5rem;
  .getInTouchRecruitingPara1 {
    font-size: $h1-font-size;
    font-weight: $h1-font-weight;
    font-family: $h1-font-family;
    color: $color-4;
    @include firstUpperCase();
    .getInTouchRecruitingPara1InnerText {
      color: $color-1;
    }
  }
  .getInTouchRecruitingPara2 {
    font-size: $h3-font-size;
    font-family: $body-font-family;
    font-weight: $body-font-weight;
    color: $color-4;
    padding-top: calc($item-padding-left-right-top-bottom * 2);
    @include firstUpperCase();
  }
  .getInTouchRecruitingButton {
    border: 1px solid $color-2;
    color: $color-4;
    font-weight: $weight;
    border-radius: $button-border-radius;
    background-color: $color-2;
    margin-top: calc($item-margin-top-bottom-left-right);
    width: 14rem;
  }
}

.calender {
  margin-top: calc($item-margin-top-bottom-left-right);
}

@media screen and (max-width: 576px), (max-width: 992px) {
  .getInTouchRecruiting {
    margin-bottom: 1rem;
    padding-left: 0;
    padding-right: 2rem;
    .getInTouchRecruitingPara1 {
      text-align: justify;
      font-size: 2rem;
    }
    .getInTouchRecruitingPara2 {
      text-align: justify;
      font-size: 1.2rem;
    }
    .getInTouchRecruitingButton {
      margin-bottom: calc($item-margin-top-bottom-left-right);
    }
  }

  .calenderImg {
    max-width: 100%;
    height: 18rem;
  }
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 576px) {
}
