@import '/src/_scss/main';

.podcastlist {
  color: $color-1;
  margin-top: 5rem;
  > div {
    padding: 0 1rem;
  }
  .podcastlistHeading2 {
    font-size: $h1-font-size;
    font-weight: $h2-font-weight;
    font-family: $h2-font-family;
    margin-bottom: 2rem;
    .specific {
      font-weight: $h1-font-weight;
    }
  }

  .podcastlist-icon img {
    width: 50px;
  }

  .podcastIframe {
    width: 100%;
    min-width: 275px;
    min-height: 400px;
    max-width: 475px;
    max-height: 800px;
    border: 0;
    overflow: hidden;
  }

  .podcastlistSubheading4 {
    color: $color-2;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .podcastlistPara1 {
    font-size: $body-font-size;
    font-weight: $body-font-weight;
    font-family: $body-font-family;
    color: $color-1;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  .podcastlistPara3 {
    font-size: 1.5rem;
    font-weight: $body-font-weight;
    font-family: $body-font-family;
    color: $color-4;
    @include firstUpperCase();

    .specific {
      color: $color-1;
    }

    .colorGold {
      color: $color-2;
      font-weight: $h3-font-weight;
    }
  }

  .podcastBoxWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    padding: 2rem;
    margin-bottom: 3rem;
    border: 2px solid black;
    border-radius: 10px;
    .rightBox {
      padding: 2rem 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  .podcastBoxWrapper img {
    max-width: 300px;
  }

  button.btn.podcastButton {
    border-radius: $button-border-radius;
    background-color: $color-1;
    color: $color-4;
    font-size: $h2-font-size;
    font-weight: $button-font-weight;
    font-family: $button-font-family;
    padding: 0.5rem 2.5rem;
    // margin-left: $item-margin-top-bottom-left-right;
    margin-top: $item-margin-top-bottom-left-right;
    .locateIcon {
      padding-left: calc($item-padding-left-right-top-bottom);
      color: $color-2;
    }
    &:hover {
      background-color: $color-2;
      color: $color-3;
      .locateIcon {
        padding-left: calc($item-padding-left-right-top-bottom);
        color: $color-3;
      }
    }
  }

  @media (max-width: 576px), (max-width: 992px) {
    .podcastBoxWrapper img {
      max-width: 200px;
    }
  }

  @media screen and (max-width: 992px) {
  }

  @media (max-width: 576px) {
    .podcastlistHeading2 {
      font-size: $h1-font-size-mobile;
      padding-left: 2rem;
    }

    .license a {
      padding: 1rem;
    }

    .podcastBoxWrapper {
      flex-direction: column;
      .rightBox {
        padding: 1rem 0;
      }
    }

    .podcastButtonWrapper {
      display: flex;
      justify-content: center;
      button.btn.podcastButton {
        padding: 1rem;
        font-size: $h2-font-size-mobile;
      }
    }
  }
}
