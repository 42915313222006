@import '/src/_scss/main';
.service {
  padding-top: calc($item-padding-left-right-top-bottom * 2);
  .serviceColPadding {
    padding-left: inherit;
    padding-right: inherit;
  }
  .serviceHeading1 {
    text-align: $text-alignment;
    background-image: linear-gradient(to right, #011940, #20548e);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    font-size: $h1-font-size;
    font-weight: $h1-font-weight;
    font-family: $h1-font-family;
  }
  .imgRow {
    width: 100%;
    height: 49.5%;
  }
  .serviceImg {
    object-fit: cover;
    width: auto;
    height: auto;
  }
  .accordionItem {
    margin-bottom: 0.5rem;
    box-shadow: 0px 8px 8px $color-3;
  }
  .serviceAccordionHeading {
    text-transform: uppercase;
    color: $color-1;
    font-size: $body-font-size;
    font-weight: $body-font-weight;
    font-family: $body-font-family;
    padding-top: calc($item-padding-left-right-top-bottom);
  }
  .serviceAccordionSubHeading {
    font-size: $body-font-size;
    font-weight: $body-font-weight;
    font-family: $body-font-family;
    padding-left: calc($item-padding-left-right-top-bottom * 6);
    padding-right: calc($item-padding-left-right-top-bottom * 10);
    @include firstUpperCase();
  }
  .serviceAccordionBodyContent {
    @include firstUpperCase();
    padding-left: calc($item-padding-left-right-top-bottom * 6);
    text-align: justify;
  }
  .nobull {
    list-style-type: none;
  }
  .serviceList {
    font-size: $body-font-size;
    font-family: $body-font-family;
    font-weight: $body-font-weight;
    margin-left: calc($item-margin-top-bottom-left-right * 3);
  }
  .plusIcon {
    color: $color-2;
    padding-right: 1rem;
  }
  .serviceButton {
    border: 1px solid $color-2;
    color: $color-1;
    font-weight: $button-font-weight;
    border-radius: $button-border-radius;
    font-family: $button-font-family;
    width: 12rem;
    margin-top: calc($item-margin-top-bottom-left-right);
    margin-left: calc($item-margin-top-bottom-left-right * 4);
    margin-bottom: calc($item-margin-top-bottom-left-right);
  }
  .serviceIconNo {
    color: $color-2;
    font-size: $h2-font-size;
    font-weight: $h2-font-weight;
    font-family: $h2-font-family;
    padding-left: calc($item-padding-left-right-top-bottom);
    padding-right: calc($item-padding-left-right-top-bottom);
  }
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  border-bottom: 0;
  .accordion-button {
    display: inherit;
  }
  .accordion-button:focus {
    color: black;
  }
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: $color-4;
}

@media screen and (max-width: 992px) {
  .service {
    padding-top: calc($item-padding-left-right-top-bottom);
    .accordionItem {
      margin-top: calc($item-padding-left-right-top-bottom);
      box-shadow: 0px 8px 8px $color-3;
    }
    .imgRow {
      width: 100%;
      height: 49.7%;
    }
    .serviceList {
      margin-left: calc($item-margin-top-bottom-left-right * 2);
    }
    .serviceButton {
      margin-left: calc($item-margin-top-bottom-left-right * 3);
    }
    .serviceAccordionSubHeading {
      padding-right: $item-padding-left-right-top-bottom %2;
      text-align: justify;
    }
  }
}

@media screen and (max-width: 576px) {
  .service {
    padding-top: calc($item-padding-left-right-top-bottom);
    .serviceImg {
      display: none;
    }
    .serviceList {
      margin-left: $item-margin-top-bottom-left-right %2;
    }
    .serviceButton {
      margin-left: calc($item-margin-top-bottom-left-right * 1);
    }
    .serviceAccordionSubHeading {
      padding-right: $item-padding-left-right-top-bottom %2;
      text-align: justify;
    }
  }
}
