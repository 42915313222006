@import '/src/_scss/main';

.container1 {
  margin-top: calc($item-margin-top-bottom-left-right * 2);
  .container1Para1 {
    font-size: $h1-font-size;
    font-weight: $h1-font-weight;
    background-image: linear-gradient(to right, #011940, #20548e);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    font-family: $h1-font-family;
    @include firstUpperCase();
    .container1Para1InnerText {
      color: $color-2;
    }
  }

  .container1Para2 {
    font-size: $body-font-size;
    font-weight: $body-font-weight;
    font-family: $body-font-family;
    @include firstUpperCase();
  }
  .container1Button {
    border: 1px solid $color-2;
    color: $color-1;
    font-weight: $button-font-weight;
    border-radius: $button-border-radius;
    font-family: $button-font-family;
    width: 12rem;
    margin-top: calc($item-margin-top-bottom-left-right);
    margin-bottom: calc($item-margin-top-bottom-left-right);
    @include firstUpperCase();
  }
}

.firstImg {
  margin-left: calc($item-margin-top-bottom-left-right * 6);
  margin-top: -$item-margin-top-bottom-left-right * 5.5;
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 576px), (max-width: 992px) {
  .container1 {
    margin-left: $item-margin-top-bottom-left-right %2;
    margin-top: calc($item-margin-top-bottom-left-right * 2);
    .container1Para1 {
      .container1Para1InnerText {
        text-align: justify;
      }
    }

    .container1Para2 {
      text-align: justify;
    }
  }

  .firstImg {
    margin-left: $item-margin-top-bottom-left-right %2;
    margin-top: calc($item-margin-top-bottom-left-right);
  }
}

@media screen and (max-width: 992px) {
  .firstImg {
    margin-top: calc($item-margin-top-bottom-left-right * 2);
  }
}
@media screen and (min-width: 1400px) {
  .container1 {
    margin-left: calc($item-margin-top-bottom-left-right * 5) !important;
  }
}
