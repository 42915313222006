@import '/src/_scss/main';
.digitalTech {
  .digitalTechImg {
    max-width: 100%;
    height: auto;
    margin-top: calc($item-margin-top-bottom-left-right * 3);
  }
  .digitalTechRight {
    background-color: $color-2;
  }
  .digitalTechPara1 {
    font-size: $h2-font-size;
    font-family: $h2-font-family;
    font-weight: $h2-font-weight;
    color: $color-1;
    margin-top: calc($item-margin-top-bottom-left-right * 4);
    padding-left: calc($item-padding-left-right-top-bottom * 2);
  }
  .digitalTechPara2 {
    font-size: $h3-font-size;
    font-family: $h3-font-family;
    font-weight: $h3-font-weight;
    color: $color-4;
    padding-top: calc($item-padding-left-right-top-bottom * 3);
    padding-left: calc($item-padding-left-right-top-bottom * 2);
    @include firstUpperCase();
  }
}

@media (max-width: 576px), (max-width: 992px) {
  .digitalTech {
    .digitalTechImg {
      margin-top: calc($item-margin-top-bottom-left-right);
    }
    .digitalTechRight {
      background-color: $color-2;
    }
    .digitalTechPara1 {
      margin-top: calc($item-margin-top-bottom-left-right * 2);
      padding-left: $item-padding-left-right-top-bottom %2;
      text-align: justify;
    }
    .digitalTechPara2 {
      font-size: $h2-font-size;
      padding-left: $item-padding-left-right-top-bottom %2;
      text-align: justify;
    }
  }
}
