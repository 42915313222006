@import '/src/_scss/main';
.yellow-bg {
  background-color: $color-2;
}

.getInTouch {
  margin-top: calc($item-margin-top-bottom-left-right * 7);
  margin-bottom: calc($item-margin-top-bottom-left-right * 7);
  .getInTouchPara1 {
    font-size: $h1-font-size;
    font-weight: $h1-font-weight;
    font-family: $h1-font-family;
    color: $color-4;
    @include firstUpperCase();
    .getInTouchPara1InnerText {
      color: $color-2;
    }
  }
  .getInTouchPara2 {
    font-size: $body-font-size;
    font-family: $body-font-family;
    font-weight: $body-font-weight;
    color: $color-4;
    padding-top: calc($item-padding-left-right-top-bottom * 2);
    @include firstUpperCase();
  }
  .getInTouchButton {
    border: 1px solid $color-2;
    color: $color-4;
    font-weight: $weight;
    border-radius: $button-border-radius;
    background-color: $color-2;
    margin-top: calc($item-margin-top-bottom-left-right);
    width: 14rem;
  }
}

.calender {
  margin-top: calc($item-margin-top-bottom-left-right);
}

@media screen and (max-width: 576px), (max-width: 992px) {
  .getInTouch {
    .getInTouchPara1 {
      text-align: justify;
      .getInTouchPara1InnerText {
        color: $color-2;
      }
    }
    .getInTouchPara2 {
      text-align: justify;
    }
    .getInTouchButton {
      margin-bottom: calc($item-margin-top-bottom-left-right);
    }
  }

  .calenderImg {
    max-width: 100%;
    height: 18rem;
  }
}

@media screen and (max-width: 992px) {
  .getInTouch {
    margin-top: $item-margin-top-bottom-left-right * 2;
    .getInTouchPara1 {
      text-align: left;
    }
  }
}

@media screen and (max-width: 576px) {
  .getInTouch {
    padding-top: calc($item-padding-left-right-top-bottom);
  }
}
