@import '/src/_scss/main';

.systemSuperiority {
  color: $color-4;
  padding: 5rem 0;
}

.systemSuperioritySection1 {
  text-align: center;
}

.systemSuperioritySection2 {
  padding: 1rem 15%;
  margin: 3rem 0;
}

.card {
  width: 20%;
  height: 410px;
  .card-header {
    font-size: 1.5rem;
    line-height: 29px;
    font-weight: 700;
    font-family: $body-font-family;
    text-align: center;
    height: 80px;
  }
  .card-body {
    line-height: 24px;
  }
  .card-body ul {
    list-style-type: none;
    padding-left: 0;
  }
  .card-body li {
    margin-bottom: 16px;
  }
  .card-body li svg {
    margin-right: 0.5rem;
  }
  img {
    width: 7rem;
    height: 100%;
  }
  &.specific {
    background-color: $color-2;
    color: $color-4;
    font-weight: 600;
    height: 420px;
  }
}

@media (max-width: 576px), (max-width: 992px) {
  .systemSuperioritySection1 {
    padding: 0 3rem;
  }
  .systemSuperioritySection2 {
    padding: 1rem 0;
  }

  .card {
    width: 20%;
    height: 450px;
    .card-header {
      font-size: 1rem;
    }
    .card-body {
      line-height: 20px;
      padding: 1rem;
    }
    .card-body li svg {
      margin-right: 0;
    }
  }
  .specific {
    height: 470px !important;
  }
}

@media (max-width: 576px) {
  .systemSuperioritySection1 {
    padding: 0 2rem;
  }
  .systemSuperioritySection2 {
    flex-direction: column;
  }
  .card {
    width: 65%;
    height: 400px;
  }
  .specific {
    height: 420px !important;
  }
}
