@import '/src/_scss/main';

.footerSection {
  margin-top: calc($item-margin-top-bottom-left-right);
  margin-bottom: calc($item-margin-top-bottom-left-right);
  .footerIcons {
    padding-top: calc($item-padding-left-right-top-bottom / 2);
    span {
      padding-right: calc($item-padding-left-right-top-bottom * 2);
      img {
        width: 2rem;
        height: 2rem;
      }
    }
  }
  .footerList {
    padding-top: calc($item-padding-left-right-top-bottom / 2);
    a {
      text-decoration: none;
    }
    span {
      padding-left: calc($item-padding-left-right-top-bottom * 2);
      font-size: $footer-font-size;
      font-family: $footer-font-family;
      color: $color-5;
      .uppercase {
        text-transform: uppercase;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .footerSection {
    .footerList {
      span {
        padding-left: calc($item-padding-left-right-top-bottom / 6);
        margin-left: 1rem;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .footerSection {
    .footerList {
      span {
        display: grid;
      }
    }
  }
}
