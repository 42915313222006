@import '/src/_scss/main';

.podcast {
  color: $color-4;
  display: flex;
  justify-content: space-between;

  .podcastRightSide {
    padding: 0 3rem;
  }

  .center {
    text-align: start;
  }

  .podcast-img {
    max-height: 800px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  .podcast-icon img {
    width: 50px;
  }

  .podcastMailLink {
    color: $color-2;
    text-decoration: none;
  }

  .podcastMailIcon {
    margin-right: 0.2rem;
    height: 1.2rem;
  }

  .podcastSubheading4 {
    color: $color-2;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .podcastPara1 {
    font-size: $h2-font-size;
    font-weight: $h2-font-weight;
    font-family: $h2-font-family;
    color: $color-4;
    margin-top: calc($item-margin-top-bottom-left-right * 6);
    text-transform: uppercase;
  }
  .podcastPara2 {
    color: $color-4;
    font-size: 3rem;
    font-weight: 700;
    font-family: $h2-font-family;
    letter-spacing: 2px;
    @include firstUpperCase();
    .colorGold {
      color: $color-2;
    }
    .colorBlue {
      color: $color-1;
    }
  }
  .podcastPara3 {
    font-size: 1.2rem;
    font-weight: $body-font-weight;
    font-family: $body-font-family;
    color: $color-4;
    @include firstUpperCase();
    .specific {
      color: $color-1;
    }
    .colorGold {
      color: $color-2;
      font-weight: $h3-font-weight;
    }
  }

  .list {
    list-style: none;
    padding: 5% 25% 5% 3%;
  }

  .list-item {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    gap: 2rem;
  }

  .list-paragraph {
    color: $color-4;
    margin: 5px 0;
  }
}

.contact-form {
  background-color: #ffc107;
  border-radius: 2rem;
  padding: 2rem;
  margin-top: -5rem;
  margin-bottom: 5rem;
}

.contact-form p {
  font-size: $h2-font-size;
  font-weight: 700;
  font-family: $h2-font-family;
}

.contact-form input {
  margin-left: 1rem;
}

.contact-form > label {
  padding: 1rem;
  color: $color-1;
}

.contactFormButton {
  border: 1px solid $color-1;
  background-color: $color-4;
  color: $color-1;
  font-size: 1.7rem;
  font-weight: 400;
  font-family: $primary-font-family;
  border-radius: 5rem;
  padding: 0.2rem 3rem;
  margin-top: 1rem;
}

.contactFormButton:hover {
  color: $color-4;
  border: 1px solid $color-4;
  background-color: $color-1;
}

@media (max-width: 576px), (max-width: 992px) {
  .podcast {
    padding-left: 1rem;
    padding-right: 1rem;
    .podcastRightSide {
      padding: 0 1rem;
    }
    .podcastPara1 {
      line-height: 25%;
      text-align: center;
      margin-top: calc($item-margin-top-bottom-left-right);
    }
    .podcastPara2 {
      margin-left: $item-margin-top-bottom-left-right %2;
      margin-top: calc($item-margin-top-bottom-left-right);
      font-size: $h2-font-size-mobile;
    }
    .podcastPara3 {
      font-size: $h3-font-size-mobile;
      margin-left: $item-margin-top-bottom-left-right %2;
      text-align: justify;
    }
    .podcast-icon img {
      width: 35px;
    }
    .list-item {
      padding: 5% 10%;
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 992px) {
  .podcast {
    .podcastRightSide {
      padding: 0 1rem;
    }
    .list {
      padding: 5% 5%;
    }
    .list-item {
      padding: 5% 0;
      gap: 1rem;
    }
  }

  .end-start {
    justify-content: center;
    align-items: center;
  }

  .podcast .podcast-img {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    max-width: 450px;
  }
}
